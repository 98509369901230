<style>
@media (max-width: 460px) {
  .resButtons {
    width: 100%;
  }
}
</style>

<template>
  <div v-if="renderComponent">
    <div class="custom-search d-flex justify-content-between flex-wrap">
      <b-form-group class="resButtons">
        <!-- <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div> -->
      </b-form-group>
      <b-form-group class="resButtons">
        <slot name="download">
          <div class="d-flex align-items-center justify-content-end">
            <b-button v-b-tooltip.hover.v-success title="Print" variant="gradient-success" class="ml-2 btn-icon"
              @click="printData">
              <feather-icon icon="PrinterIcon" />
            </b-button>
            <!-- <b-button
              v-b-tooltip.hover.v-success
              title="Pdf"
              variant="gradient-success"
              class="ml-2 btn-icon"
              @click="pdfData"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button> -->
            <b-button v-b-tooltip.hover.v-success title="Excel" variant="gradient-success" class="ml-2 btn-icon"
              @click="clickExcel">
              <!-- @click="excelData" -->
              <feather-icon icon="FileTextIcon" />
            </b-button>
            <span class="ml-2 test resLines">
              <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                <template v-slot:button-content>
                  <feather-icon width="30" height="30" icon="AlignRightIcon" size="16"
                    class="text-body align-middle mr-25" />
                </template>
                <div class="example" style="
                    width: 6cm;
                    height: 7cm;
                    overflow: hidden scroll;
                    padding: 6px 20px;
                  ">
                  <b-form-group>
                    <label class="h5"> Select Field </label>
                    <b-form-checkbox-group id="checkbox-group-1" v-model="columnFilter" @input="isFilterColumn"
                      :options="columnsData" name="field" class="demo-inline-spacing" />
                  </b-form-group>
                </div>
              </b-dropdown>
            </span>
          </div>
        </slot>
      </b-form-group>
    </div>
    <div style="display: none">
      <div id="printTable">
        <slot name="printHeader">
          <div style="display: flex; justify-content: center; width: 100%">
            <h3 style="margin: 0px">
              {{ routeName }}
            </h3>
          </div>
        </slot>
        <table border="1" cellpadding="1" style="border-collapse: collapse; width: 100%">
          <thead>
            <tr>
              <th v-for="(item, index) in columnsItem" :key="index" class="p-1">
                {{ item.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="index">
              <td v-for="data in columnsItem" :key="data.id" style="padding: 5px" v-if="data.visibale != false">
                <span v-if="data.dynamic && item[data.field]">
                  {{ item[data.field][data.responseField] }}
                </span>
                <slot v-else name="customPrint" :data="data" :item="item">
                  {{ item[data.field] }}
                </slot>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div id="all-data">
      <slot name="upperbody"> </slot>
      <!-- table -->
      <vue-good-table :columns="columnsItem" :rows="data" :rtl="direction" :line-numbers="true" :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" :pagination-options="{
        enabled: false,
        perPage: pageLength,
      }" collapsable:2>
        <template slot="table-column" slot-scope="props">
          <slot name="customColumn" :props="props">
            {{ props.column.label }}
          </slot>
        </template>
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <div v-if="props.column.field === 'image'" class="text-nowrap">
            <b-image :src="props.row.attachment" class="mx-2" size="45px" />
            <!-- <span class="text-nowrap">{{ props.row.fullName }}</span> -->
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.rating == true">
            <b-badge v-if="props.row[props.column.field] == 'Poor'" variant="dark">
              {{ props.row[props.column.field] }}
            </b-badge>
            <b-badge v-if="props.row[props.column.field] == 'Fair'" variant="warning">
              {{ props.row[props.column.field] }}
            </b-badge>
            <b-badge v-if="props.row[props.column.field] == 'Satisfactory'" variant="info">
              {{ props.row[props.column.field] }}
            </b-badge>
            <b-badge v-if="props.row[props.column.field] == 'Good'" variant="primary">
              {{ props.row[props.column.field] }}
            </b-badge>
            <b-badge v-if="props.row[props.column.field] == 'Excellent'" variant="success">
              {{ props.row[props.column.field] }}
            </b-badge>
          </span>
          <span v-else-if="props.column.field == 'action'">
            <slot :name="props.column.field" :row="props.row"></slot>
          </span>
          <span v-else>
            <slot name="customField" :props="props">
              {{ props.formattedRow[props.column.field] }}
            </slot>
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import axios from "@/components/axios";
import exportFromJSON from "export-from-json";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BButton,
  BDropdownItem,
  VBTooltip,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
// import { codeColumnSearch } from "./code";

export default {
  props: ["title", "columns", "data"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormCheckboxGroup,
  },
  data(props) {
    return {
      props,
      visible: false,
      pageLength: 10,

      renderComponent: false,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"), // JSON.parse()
      searchTerm: "",
      columnsItem: [],
      columnFilter: [],
      columnsData: [],
      routeName: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.routeName =
      this.$route.meta &&
        this.$route.meta.breadcrumb &&
        this.$route.meta.breadcrumb.length > 0
        ? this.$route.meta.breadcrumb[this.$route.meta.breadcrumb.length - 1]
        : {};

    this.routeName = this.routeName && this.routeName.text ? this.routeName.text : "";
    this.columnsItem = this.columns;
    this.columnsItem.map((item) => {
      const data = {
        text: item.label,
        value: item,
      };
      this.columnsData.push(data);
    });
    this.columnFilter = this.columnsItem;
    this.columnsItem = this.columnFilter;
    this.forceRerender();
  },
  methods: {
    isFilterColumn() {
      this.columnsItem = [];
      const data = this.columnFilter;
      this.columnFilter = [];
      data.map((item) => {
        if (item.field !== "action") {
          this.columnFilter.push(item);
        }
      });
      this.columnsItem = this.columnFilter;
    },
    clickExcel() {
      this.data1 = [];

      this.data.forEach((dataItem) => {
        const transformedItem = {};

        this.columns.forEach((col) => {
          if (dataItem.hasOwnProperty(col.field)) {

            transformedItem[col.label] = col.dynamic
              ? dataItem[col.field]
                ? dataItem[col.field][col.responseField]
                : ""
              : dataItem[col.field];
          }
        });

        this.data1.push(transformedItem);
      });

      const fileName = "exported-data";
      const fileExportType = "csv";
      const exportType = exportFromJSON.types[fileExportType];

      exportFromJSON({
        data: this.data1,
        fileName: fileName,
        exportType: exportType,
      });
    },
    printData() {
      const divToPrint = document.getElementById("printTable");
      // const newWin = window.open();
      const newWin = window.open("", "_blank");
      newWin.document.write(divToPrint.outerHTML);
      newWin.print();
      newWin.close();
    },
    setSr() {
      if (this.data.length !== 0) {
        this.data.map((item, index) => {
          item.id = index + 1;
        });
      }
      return true;
    },

    pdfData() {
      const data = document.getElementById("printTable");
      html2canvas(data).then((canvas) => {
        const fileWidth = 208;
        const fileHeight = (canvas.height * fileWidth) / canvas.width;
        const fileuri = canvas.toDataURL("image/png");
        const PDF = new jsPDF("p", "mm", "a4");
        const position = 0;
        PDF.addTable(fileuri, "PNG", 0, position, fileWidth, fileHeight);
        PDF.addFont(data);
        PDF.save("angular-demo.pdf");
      });
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
  },
};
</script>
<style></style>
